import React from "react"

const FunFact = ({content}) => {
    return (
        <section className="cw-container-fluid">
            <div className="cw-container fun-fact-section">
                <div className="fun-fact">
                    <div dangerouslySetInnerHTML={{__html: content}}></div>
                </div>
            </div>
        </section>
    )
}

export default FunFact
