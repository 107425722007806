import React from "react"
import Layout from "../components/layout";
import {graphql} from "gatsby";
import SEO from "../components/seo";
import ProjectIntroSection from "../sections/project/project-intro-section";
import ProjectAboutSection from "../sections/project/project-about-section";
import FunFact from "../components/fun-fact";
import ProjectChallengesAndSolutionSection
    from "../components/project/project-challenges-and-solution-section";
import ProjectTechnologiesSection
    from "../sections/project/project-technologies-section";
import ProjectEffectSection from "../components/project/project-effect-section";
import NextProjectSection from "../sections/project/next-project-section";
import ProjectImageDividerSection
    from "../components/project/project-image-divider-section";
import CTASection from "../sections/cta-section";

export default ({location, data, pageContext }) => {
    const {theme, dominatingColor} = pageContext;
    const project = data.project;
    let nextProject = data.nextProject;
    const projectsInCategory = (location.state && location.state.projectsInCategory) || null;
    if(projectsInCategory) {
        let index = projectsInCategory.findIndex(p => p.slug === project.slug) + 1;
        index = index % projectsInCategory.length;
        nextProject = projectsInCategory[index];
    }
    return (
        <Layout theme={theme}>
            <SEO title={project.name}/>
            <ProjectIntroSection project={project} theme={theme} bgColor={dominatingColor}/>
            <ProjectAboutSection content={project.introduction}/>
            <FunFact content={project.funFact}/>
            <ProjectChallengesAndSolutionSection project={project}/>
            <ProjectTechnologiesSection project={project}/>
            <ProjectEffectSection project={project}/>
            <ProjectImageDividerSection image={project.dividerImage} name={project.name}/>
            <CTASection/>
            <NextProjectSection project={nextProject} projectsInCategory={projectsInCategory} />
        </Layout>
    )
}

export const query = graphql`
    query($slug: String, $nextSlug: String) {
        nextProject: codewaveProject( slug: { eq: $nextSlug }) {
            slug,
            name,
            paginationThumbnail {
                extension
                id
            }
        }
        project: codewaveProject( slug: { eq: $slug }) {
            id,
            slug,
            client {
                name
                logo {
                    id
                    extension
                },
                logoColored {
                    id
                    extension
                }
            },
            name,
            shortDescription,
            portfolioImage {
                extension
                id
            },
            mainImage {
                extension
                id
            },
            introImage {
                extension
                id
            },
            projectUrlAddress,
            introduction,
            funFact,
            usedTechnologies {
                usageDescription
                technology {
                    name
                    logo {
                        id
                        extension
                    }
                }
            },
            solutionDescription,
            solutionImage {
                id
                extension
            },
            effectDescription,
            effectImage {
                id
                extension
            },
            dividerImage {
                extension
                id
            },
            paginationThumbnail {
                extension
                id
            }
        }
    }
`