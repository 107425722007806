import React from "react"
import ProjectTechnology from "../../components/project/project-technology";

const ProjectTechnologiesSection = ({project}) => {


    return (
        <section className="cw-container-fluid">
            <div className="cw-container content-section projects-technologies-section">
                <h2 className="section-header text-center">Technologies</h2>
                <div className="project-technologies">
                    {project.usedTechnologies && project.usedTechnologies.map((technologyUse, index) => <ProjectTechnology key={index} description={technologyUse.usageDescription} technology={technologyUse.technology && technologyUse.technology[0]} />)}
                </div>
            </div>
        </section>
    )
}

export default ProjectTechnologiesSection