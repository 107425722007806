import React from "react"
import imageUrl from "../../helpers/flotiqImage";
import {graphql, useStaticQuery} from "gatsby";

const ProjectChallengesAndSolutionSection = ({project}) => {
    const data = useStaticQuery(graphql`
        query {
            challengesSectionBG: file(relativePath: { eq: "backgrounds/cdwv-bg-1.svg" }) {
                publicURL
            }
        }
    `);

    let challengesSectionSectionStyle = {
        backgroundImage: `url(${data.challengesSectionBG.publicURL})`
    }

    return (
        <section className="cw-container-fluid" style={challengesSectionSectionStyle}>
            <div className="cw-container content-section challenges-and-solution-section">
                <div className="challenges-and-solution-section__content-column">
                    <h2 className="section-header section-header--colored">Challenges & Solution</h2>
                    <div className="section-description" dangerouslySetInnerHTML={{__html: project.solutionDescription}}></div>
                </div>
                <div className="challenges-and-solution-section__image-column">
                    <img alt={project.name} src={imageUrl(project.solutionImage)}/>

                </div>
            </div>
        </section>
    )
}

export default ProjectChallengesAndSolutionSection