import React from "react"
import imageUrl from "../../helpers/flotiqImage";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const ProjectIntroSection = ({project, theme, bgColor}) => {
    let projectIntroStyle = {
        backgroundImage: `url(${imageUrl(project.mainImage)})`,
        backgroundColor: bgColor || "transparent"
    }

    return (
        <section className="cw-container-fluid" style={projectIntroStyle}>
            <div className={`intro intro--project`}>
                <div className="intro__column">
                    <div className={`intro-cta ${theme ? "intro-cta--" + theme : "" }`}>
                        <img className="intro-cta__client-logo" alt={project.client[0].name} src={imageUrl(project.client[0].logoColored, {width: 75, height: 75})}/>
                        <h1 className="intro-cta__header">{project.client[0].name}</h1>
                        <div className="intro-cta__description" dangerouslySetInnerHTML={{__html: project.shortDescription}}></div>
                        <div className="intro-cta__buttons">
                            <AniLink duration={.5} fade to="/contact" className="btn">Get in Touch</AniLink>
                            {project.projectUrlAddress && <a href={project.projectUrlAddress} className="btn btn--transparent btn--see-the-effect" >See the effect</a>}
                        </div>
                    </div>

                </div>
                <div className="intro__column intro__column--image">
                    <img alt={project.name} src={imageUrl(project.introImage)}/>
                </div>
            </div>
        </section>
    )
}

export default ProjectIntroSection