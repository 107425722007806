import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import RightArrow from "../../images/svg/right-arrow.svg"
import imageUrl from "../../helpers/flotiqImage";

const NextProjectSection = ({ project, projectsInCategory }) => {
  return (
    <section className="cw-container-fluid">
      <div className="cw-container content-section next-project-section">
        <AniLink duration={.5} fade to={`/portfolio/${project.slug}`} state={{ projectsInCategory }}>
          <div className="next-project-section__content-col">
            <p className="next-project-section__label">Next project:</p>
            <h3 className="next-project-section__project-name">{project.name}</h3>
            <RightArrow className="next-project-section__arrow" />
          </div>
          <div className="next-project-section__image-col">
            <img className="next-project-section__image" alt={project.name} src={imageUrl(project.paginationThumbnail)} />
          </div>
        </AniLink>
      </div>
    </section>
  )
}

export default NextProjectSection