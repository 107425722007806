import React from "react"
import imageUrl from "../../helpers/flotiqImage";

const ProjectEffectSection = ({project}) => {

    return (
        <section className="cw-container-fluid">
            <div className="cw-container content-section project-effect-section">
                <div className="project-effect-section__content-column">
                    <h2 className="section-header section-header--colored">Effect</h2>
                    <div className="section-description" dangerouslySetInnerHTML={{__html: project.effectDescription}}></div>
                </div>
                <div className="project-effect-section__image-column">
                    <img alt={project.name} src={imageUrl(project.effectImage)}/>
                </div>
            </div>
        </section>
    )
}

export default ProjectEffectSection