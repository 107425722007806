import React from "react"
import imageUrl from "../../helpers/flotiqImage";

const ProjectTechnology = ({description, technology}) => {
    return (
        <div className="project-technology">
            <img className="project-technology__logo" alt={description} src={imageUrl(technology.logo, {width: 250})} />
            <h4 className="project-technology__name">
                {technology.name}
            </h4>
            <div className="project-technology__usage" dangerouslySetInnerHTML={{__html: description}}></div>
        </div>
    )
}

export default ProjectTechnology