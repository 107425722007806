import React from "react"
import imageUrl from "../../helpers/flotiqImage";

const ProjectImageDividerSection = ({name, image}) => {

    return (
        <section className="cw-container-fluid project-image-divider-section">
            <img alt={name} src={imageUrl(image)}/>
        </section>
    )
}

export default ProjectImageDividerSection