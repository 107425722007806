import React from "react"

const ProjectAboutSection = ({content}) => {
    return (
        <section className="cw-container content-section project-about-section">
            <div className="project-about-section__description">
                <div dangerouslySetInnerHTML={{__html: content}}></div>
            </div>
        </section>
    )
}

export default ProjectAboutSection